import { Vue, Component, Watch } from 'vue-property-decorator';
import { Getter, State as StateClass } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import { sortBy, uniq } from 'lodash';
import Sorting from '@/components/properties/toolbar/sorting/Sorting.vue';
import { Order, SortingChangeEmit, SortOptions } from '@/components/properties/toolbar/sorting/Sorting';
import { collections } from '@/vue';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import FooterExpanded from '@/components/common/footer-expanded/FooterExpanded.vue';
import LocationFilter from '@/components/properties/toolbar/filtering/location/LocationFilter.vue';
import { Country } from '@/components/properties/toolbar/filtering/location/LocationFilter';
import Footer from '@/components/common/footer/Footer.vue';
import ToolbarSearch from '@/components/properties/toolbar/search/ToolbarSearch.vue';
import ToolbarViewSwitcher from '@/components/properties/toolbar/view-switcher/ToolbarViewSwitcher.vue';
import ToolbarPagination from '@/components/properties/toolbar/pagination/ToolbarPagination.vue';
import PropertiesGridList from '@/components/properties/grid-list/PropertiesGridList.vue';
import Support from '@/components/common/support/Support.vue';
import { premiumAssetTemplate } from '@/helpers/premium-assets/template';
import { metaTitles, metaDescriptions, metaURL, metaLocale, linkAlternate } from '@/helpers/meta';
import { titleTemplate } from '@/helpers/meta/title-template';
import { intercom } from '../../../whitelabel.config';

// Function that sorts first by shares available (if > 0 has prio), then by reservation type
const sortAssets = (assets: Asset[], extraSorting?: (a: Asset, b: Asset) => number): Asset[] =>
  sortBy(
    assets.sort((a, b): number => {
      if (a.sharesAvailable && !b.sharesAvailable) {
        return -1;
      }

      if (!a.sharesAvailable && b.sharesAvailable) {
        return 1;
      }

      if (!extraSorting) {
        return 0;
      }

      return extraSorting(a, b);
    }),
    'reservation',
  );

@Component({
  components: {
    BannerLegal,
    Navbar,
    FooterExpanded,
    LocationFilter,
    Footer,
    Sorting,
    ToolbarSearch,
    ToolbarViewSwitcher,
    ToolbarPagination,
    PropertiesGridList,
    Support,
  },
})
export default class Properties extends Vue {
  metaInfo(): MetaInfo {
    const { path } = this.$route;
    const { locale, availableLocales } = this.$i18n;
    return {
      title: titleTemplate(this.$t('meta.properties.title').toString()),
      link: [
        ...linkAlternate(path, availableLocales),
      ],
      meta: [
        ...metaTitles(this.$t('meta.properties.title').toString()),
        ...metaDescriptions(this.$t('meta.properties.description').toString()),
        ...metaURL(path),
        ...metaLocale(locale, availableLocales),
      ],
    };
  }

  intercom = intercom;

  promisedAssetsCollection = collections.assets;

  viewType: 'grid' | 'list' = 'grid';
  orderType: 'search' | 'country' | SortOptions = 'date';
  assetsOrder: Order = 'DESC';
  searchText = '';
  filteredAssets: Asset[] = [];
  selectedCountries: string[] = [];
  locations: Country[] = [];
  maxAssetsPerPage = 6;
  paginationPosition = 0;

  @StateClass user!: State['user'];
  @StateClass assets!: State['assets'];
  @Getter isUserLoggedIn!: boolean;

  @Watch('assets', { immediate: true })
  onAssetsChange(newAssets: Asset[]): void {
    // Resetting filteredAssets
    this.filteredAssets = sortAssets([...newAssets]);

    // Setting the filterable locations by filtered assets
    this.locations = uniq(this.filteredAssets.map(({ country }): string => country)).map((country: string): Country => ({ name: country, isSelected: false }));

    if (!this.isUserLoggedIn) {
      this.filteredAssets = this.filteredAssets.map((asset): Asset => ({
        ...asset,
        // The id field is non-enumerable so we need to copy it explicitly since the spread operator won't copy it
        id: asset.id,
        // Use fake data for premium assets
        ...(asset.premium && premiumAssetTemplate(this.$i18n.locale)),
      }));
    }
  }

  mounted(): void {
    this.$watch(
      // Watching all those data inputs so we can do the same functionality in one same place
      // @ts-ignore
      (vm): any => [vm.orderType, vm.assetsOrder, vm.searchText, vm.selectedCountries].join(),
      (): void => {
        switch (this.orderType) {
          case 'date': {
            this.filteredAssets = sortAssets(
              this.filteredAssets,
              (a, b): number => this.assetsOrder === 'ASC' ? a.startDateTime.seconds - b.startDateTime.seconds : b.startDateTime.seconds - a.startDateTime.seconds,
            );
            break;
          }
          case 'search': {
            if (!this.searchText) {
              this.filteredAssets = sortAssets([...this.assets]);
            } else {
              const loweredCaseSearchText = this.searchText.toLocaleLowerCase();
              this.filteredAssets = sortAssets([
                ...this.assets.filter((asset): boolean => [asset.name, asset.city, (this.$options.filters as any).transformDate(asset.startDateTime)]
                  .map((field): string => field.toLocaleLowerCase())
                  .some((field): boolean => field.includes(loweredCaseSearchText)))]);
            }

            // Descending order by default, so we order only when ascending is selected
            if (this.assetsOrder === 'ASC') {
              this.orderType = 'date';
            }
            break;
          }
          case 'country': {
            if (!this.selectedCountries.length) {
              this.filteredAssets = sortAssets([...this.assets]);
            } else {
              this.filteredAssets = sortAssets(this.assets.filter(({ country }): boolean => this.selectedCountries.includes(country)));
            }
            break;
          }
          default:
            // there should bot be a sorting option for which we don't have a sorting algorithm in place
            // eslint-disable-next-line no-case-declarations
            const exhaustiveCheck: never = this.orderType;
            throw new Error(`Unhandled order case: ${exhaustiveCheck}`);
        }
      },
    );
  }

  get paginatedAssets(): Asset[] {
    return this.paginate(this.filteredAssets);
  }

  get totalPages(): number {
    return Math.ceil(this.filteredAssets.length / this.maxAssetsPerPage);
  }

  handlePagination(page: number): void {
    this.paginationPosition = (page - 1) * this.maxAssetsPerPage;
  }

  paginate(assetsArray: Asset[]): Asset[] {
    return assetsArray.slice(this.paginationPosition, this.paginationPosition + this.maxAssetsPerPage);
  }

  handleOrder(emitObj: SortingChangeEmit): void {
    this.assetsOrder = emitObj.order;
    this.orderType = emitObj.typeOfSort;
    try {
      this.$gtm.trackEvent({
        event: 'properties_order',
        email: this.user?.email,
        type: this.orderType,
        order: emitObj.order,
      });
    } catch (e) { /* Silent error */ }
  }

  handleSearch(searchText: ''): void {
    this.searchText = searchText;
    this.orderType = 'search';
    try {
      this.$gtm.trackEvent({
        event: 'properties_search',
        email: this.user?.email,
        type: searchText,
      });
    } catch (e) { /* Silent error */ }
  }

  handleCountryFilterChange(countries: string[]): void {
    this.selectedCountries = countries;
    this.orderType = 'country';
  }
}
